<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		https://www.freepik.com/premium-vector/robot-text-effect-editable-techno-crypto-text-style_24544441.htm#&position=2&from_view=home
		<br>
		https://www.freepik.com/premium-vector/dragon-text-effect-editable-legend-warrior-text-style_24544450.htm#&position=25&from_view=home
		<br>
		{{articleId}}
	</div>
</template>


<script>
	import {
		down,
		b33591
	} from '@/api/admin/login'
	export default {
		name: 'show',
		components: {

		},
		data() {
			return {
				fullscreenLoading: false,
				articleId:0
			}
		},
		methods: {

		},
		created() {
			document.title = this.$route.params.id ? this.$route.params.id : this.$route.params.username
			console.log(this.$route.params.id)
			this.articleId = this.$route.params.id
		},


	}
</script>
<style>
	
</style>
